import { MdConnectWithoutContact } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Logo } from '../Logo';
import { MenuDropdown } from '../MenuDropdown';

export function Navbar() {
  return (
    <nav className="p-4 bg-gray-900">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/login" key="certifications">
          <div className="flex items-center">
            <Logo />
            <p className="text-white text-xl ml-2 font-bold">Assban</p>
          </div>
        </Link>

        <div className="flex space-x-4">
          <Link to="/faq" key="faq">
            <button
              className="text-white hover:opacity-75 flex items-center"
              type="button"
              data-testid="dropdown-button"
            >
              <MdConnectWithoutContact
                className="mr-2"
                size={20}
                color="white"
              />
              Contato
            </button>
          </Link>
          <MenuDropdown />
        </div>
      </div>
    </nav>
  );
}
