import platform from 'platform';
import { useEffect, useState } from 'react';
import { FaRegPlayCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  useGetQuestionsParsedMutation,
  useGetQuizLogMutation,
  useStartQuizMutation,
} from '../../features/exam/certificationApiSlice';
import { setQuestions } from '../../features/exam/examSlice';
import {
  Certification,
  CertificationType,
  LoadEnrollOutput,
} from '../../types/certifications/Certification';
import { CameraComponent } from '../Camera';
import { Infringement } from '../Infringement';
import { Loading } from '../Loading';
import { Ping } from '../Ping';
import { Timer } from '../Timer';
import { FormQuestion } from './FormQuestion';

type PropsQuestion = {
  certificationLog?: LoadEnrollOutput;
  certification?: Certification;
  type?: CertificationType | null;
};

export function Question({
  certification,
  certificationLog,
  type,
}: PropsQuestion) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [finalize, setFinalize] = useState(false);
  const [load, setLoad] = useState(false);

  const [startQuiz] = useStartQuizMutation();
  const [getQuizLog, { data: quizLog }] = useGetQuizLogMutation();
  const [getQuestions, { data: questions }] = useGetQuestionsParsedMutation();

  async function handleExam() {
    setLoad(true);
    const appVersion = process.env.REACT_APP_VERSION || '0.0.0';

    if (certificationLog?.certification) {
      try {
        const resp = await startQuiz({
          _id: certificationLog._id,
          ...(certification?.monitoring === 'select' &&
            type && { monitoring: type }),
          device: `${platform.name} ${platform.version} on ${platform.os?.family} ${platform.os?.version}`,
          origin: 'web',
          appVersion,
        });

        let quizId;

        if (certificationLog.quiz_log) {
          quizId = await getQuizLog(certificationLog.quiz_log);
        } else if ('data' in resp) {
          quizId = await getQuizLog(resp.data.quiz_log);
        }

        if (quizId && 'data' in quizId) {
          const questionsData = await getQuestions(quizId?.data[0]?.quiz);

          if (questionsData && 'data' in questionsData) {
            const question = questionsData?.data;

            dispatch(setQuestions({ questions: question }));
          }
        }

        setLoad(false);
      } catch (error) {
        setLoad(false);
        console.error({ error });
      }
    }
  }

  useEffect(() => {
    handleExam();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTypeMonitoring(item: string) {
    if (type) return item === type;

    return certificationLog?.certification === item;
  }

  if (load) {
    return <Loading />;
  }

  if (quizLog?.[0]?.finished?.$date) {
    toast.warn(`Exame já finalizado`, {
      position: 'top-center',
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    navigate(`/result/${certificationLog!._id}`);
  }

  return (
    <div className="container mx-auto">
      {questions?.length && (
        <>
          <div className="flex justify-between items-center">
            <div className="w-1/2">
              <h1 className="text-2xl truncate w-full">
                {certification?.title}
              </h1>
              <Timer
                // @ts-ignore
                startedTime={new Date(quizLog?.[0]?.started?.$date)}
                expiryTime={certification!.quiz_duration}
                onExpireTime={() => setFinalize(true)}
              />
            </div>

            <Infringement
              certification_log={certificationLog!._id}
              onFinalize={() => setFinalize(true)}
            />
            <Ping certification_log={certificationLog!._id} />

            {handleTypeMonitoring('video') && (
              <div className="flex-col hidden md:flex">
                <p className="ml-auto text-2xl mb-2">PASSO 3 de 3</p>

                <p className="flex items-center">
                  <FaRegPlayCircle className="mr-2" size={20} /> Sorria você
                  está sendo filmado
                </p>
              </div>
            )}

            {handleTypeMonitoring('video') && (
              <div>
                <CameraComponent
                  type="video"
                  certification_log={certificationLog!._id}
                />
              </div>
            )}
          </div>

          {quizLog?.[0] && (
            <FormQuestion
              quizLog={quizLog[0]}
              finalize={finalize}
              logId={certificationLog!._id}
            />
          )}
        </>
      )}
    </div>
  );
}
