import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { apiSlice } from '../../app/api/apiSlice';
import { Correspondente, UserClient } from '../../types/auth/User';
import { UploadResult } from '../../types/certifications/Certification';
import { getRegistrationToken } from '../../utils/getRegistrationToken';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { CreateUser } from './SignUp';

export const signUpApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    createUser: mutation<{ code: string; status: string }, CreateUser>({
      query: payload => ({
        url: `database/registration__c`,
        method: 'PUT',
        headers: {
          Authorization: getRegistrationToken(),
        },
        body: payload,
      }),
    }),

    sendMediaRegister: mutation<UploadResult, FormData>({
      query: payload => ({
        url: `upload/file`,
        method: 'POST',
        headers: {
          Authorization: getRegistrationToken(),
        },
        body: payload,
      }),
    }),

    _loadUser: query<UserClient[], { id: string }>({
      query: id => ({
        url: 'database/practa_user__c/aggregate?strict=true',
        method: 'POST',
        headers: {
          Authorization: getRegistrationToken(),
        },
        body: [
          {
            $match: id,
          },
        ],
      }),
    }),

    getUserByCode: mutation<
      [
        {
          _id: string;
          name: string;
          email: string;
          extra: {
            cpf: string;
            code: string;
          };
        },
      ],
      string
    >({
      query: code => ({
        url: `database/player?q='extra.code':'${code}'`,
        method: 'GET',
        headers: {
          Authorization: getRegistrationToken(),
        },
      }),
    }),

    loadUser: query<UserClient[], { _id: string; renew: boolean }>({
      async queryFn({ _id, renew }, _api, _extraOptions, baseQuery) {
        let userData;
        if (!renew) {
          const userPracta = await baseQuery({
            url: 'database/practa_user__c/aggregate?strict=true',
            method: 'POST',
            headers: {
              Authorization: getRegistrationToken(),
            },
            body: [
              {
                $match: { _id },
              },
            ],
          });

          if (userPracta.error) {
            return { error: userPracta.error };
          }

          [userData] = userPracta.data as UserClient[];
        } else {
          const userBradescoLegacy = await baseQuery({
            url: 'database/bradesco_legacy__c/aggregate?strict=true',
            method: 'POST',
            headers: {
              Authorization: getRegistrationToken(),
            },
            body: [
              {
                $match: { _id },
              },
            ],
          });

          if (userBradescoLegacy.error) {
            return { error: userBradescoLegacy.error };
          }

          [userData] = userBradescoLegacy.data as UserClient[];
        }

        const result = await baseQuery({
          url: 'database/correspondente__c/aggregate?strict=true',
          method: 'POST',
          headers: {
            Authorization: getRegistrationToken(),
          },
          body: [
            {
              $match: {
                cnpj: userData.cnpj,
              },
            },
          ],
        });

        const data = result.data as Correspondente[];

        const resultPlayer = await baseQuery({
          url: 'database/player/aggregate?strict=true',
          method: 'POST',
          headers: {
            Authorization: getRegistrationToken(),
          },
          body: [
            {
              $match: {
                _id: sanitizeCpf(userData.cpf),
              },
            },
          ],
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hasUserRegister = resultPlayer.data as any;

        if (data.length > 0 && hasUserRegister.length > 0) {
          userData = {
            ...userData,
            userAlreadyExists: true,
          };
        }

        if (data.length > 0) {
          userData = {
            ...userData,
            correspondente: data[0],
          };
        }

        return data
          ? {
              data: [
                {
                  ...userData,
                },
              ] as UserClient[],
            }
          : { error: result.error as FetchBaseQueryError };
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useSendMediaRegisterMutation,
  useGetUserByCodeMutation,
  useLoadUserQuery,
} = signUpApiSlice;
