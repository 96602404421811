import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { logout } from '../../features/auth/authSlice';
import { RootState } from '../store';

const baseUrl =
  process.env.REACT_APP_BASE_URL || 'https://service2.funifier.com/v3';

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const { token } = (getState() as RootState).auth;

    if (
      (!headers.has('Authorization') ||
        !headers.get('Authorization')?.startsWith('Basic')) &&
      !endpoint.includes('getGlobal')
    ) {
      if (token?.access_token) {
        headers.set('Authorization', `Bearer ${token.access_token}`);
      }
    }

    return headers;
  },
});

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithInterceptor,
  reducerPath: 'funifierApi',
  tagTypes: ['Auth'],
  endpoints: () => ({}),
});
