/* eslint-disable react/no-danger */
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import 'react-accessible-accordion/dist/fancy-example.css';

import { selectUser } from '../../features/auth/authSlice';
import {
  useGetGlobalQuery,
  useGetFAQQuery,
} from '../../features/my-certifications/myCertificationsApiSlice';

export function About() {
  const user = useSelector(selectUser);

  const { data } = useGetGlobalQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const { data: faq } = useGetFAQQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  return (
    <div className="relative text-lg space-y-3">
      <h1 className="text-2xl font-bold mb-2">
        {data?.title} {user?.name},
      </h1>

      {data?.description && (
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
        />
      )}

      <iframe
        className="w-full aspect-video rounded-lg"
        src={data?.video_url}
        title="Bem-vindo(a)"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />

      {data?.regulation?.url && (
        <>
          <p className="font-extrabold">
            Leia o{' '}
            <a
              className="text-blue-500 underline font-bold"
              href={data?.regulation?.url}
              target="_blank"
              rel="noreferrer"
            >
              Regulamento
            </a>{' '}
            completo e o{' '}
            <a
              className="text-blue-500 underline font-bold"
              href={data?.manual?.url}
              target="_blank"
              rel="noreferrer"
            >
              Manual do Candidato
            </a>
            .
          </p>
          <p>
            Abaixo seguem informações importantes para realização da prova.
            Informe-se e faça um ótimo exame!
          </p>
        </>
      )}
      <div className="mt-5">
        <Accordion allowZeroExpanded>
          {faq?.map(item => (
            <AccordionItem key={item._id}>
              <AccordionItemHeading>
                <AccordionItemButton>{item.title}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
