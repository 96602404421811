import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { BsBookmarkFill, BsPencilFill } from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout, selectUser } from '../../features/auth/authSlice';

export function MenuDropdown() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);

  async function handleLogout() {
    setShowMenu(false);
    dispatch(logout());
  }

  return (
    <div className="relative" data-testid="container-dropdown">
      {user?.name && (
        <button
          className="text-white hover:opacity-75 flex items-center"
          type="button"
          data-testid="dropdown-button"
          onClick={() => setShowMenu(!showMenu)}
        >
          {user?.name}

          <AiOutlineCaretDown className="ml-2" size={15} color="white" />
        </button>
      )}

      {showMenu && (
        <div
          className="-right-10 absolute bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4"
          id="dropdown"
          data-testid="dropdown"
        >
          <ul className="py-1 w-44" aria-labelledby="dropdown">
            <li>
              <Link
                to="/my-certifications"
                key="certifications"
                onClick={() => setShowMenu(false)}
                data-testid="certifications"
                className="text-sm hover:bg-gray-100 text-gray-700 px-4 py-2 flex items-center"
              >
                <BsBookmarkFill
                  className="mr-2"
                  size={15}
                  color="rgb(55 65 81)"
                />
                Minhas Inscrições
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                key="profile"
                data-testid="profile"
                onClick={() => setShowMenu(false)}
                className="text-sm hover:bg-gray-100 text-gray-700 px-4 py-2 flex items-center"
              >
                <BsPencilFill
                  className="mr-2"
                  size={15}
                  color="rgb(55 65 81)"
                />
                Editar Perfil
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                key="logout"
                data-testid="logout"
                onClick={() => handleLogout()}
                className="text-sm hover:bg-gray-100 text-gray-700 px-4 py-2 flex items-center w-full"
              >
                <FiLogOut className="mr-2" size={15} color="rgb(55 65 81)" />
                Sair
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
