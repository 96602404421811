import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as z from 'zod';

import { selectUser } from '../../features/auth/authSlice';
import { useGetGlobalQuery } from '../../features/my-certifications/myCertificationsApiSlice';
import { Certification } from '../../types/certifications/Certification';
import { Button } from '../Button';

type PropsRequirements = {
  certification?: Certification;
  onChangeStep: () => void;
};

const RequirementsSchema = z.object({
  accept: z.string(),
});

type SignInForm = z.infer<typeof RequirementsSchema>;

export function Requirements({
  certification,
  onChangeStep,
}: PropsRequirements) {
  const user = useSelector(selectUser);

  const { data } = useGetGlobalQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInForm>({
    mode: 'onBlur',
    resolver: zodResolver(RequirementsSchema),
  });

  return (
    <div className="container mx-auto mt-10">
      <div className="border-b border-solid border-gray-300 pb-5 flex flex-col-reverse md:flex-row justify-between">
        <div>
          <p className="text-2xl mb-2">Prova de {certification?.title}</p>

          <ul>
            <li>
              Candidato: <b>{user?.name}</b>
            </li>
            <li>
              Duração da Prova: <b>{certification?.quiz_duration}</b>
            </li>
            <li>
              Número de Questões: <b>{certification?.total_questions}</b>
            </li>
            <li>
              Média para Aprovação: <b>{70}%</b>
            </li>
          </ul>
        </div>

        <p className="mb-5 md:mb-0 text-2xl">PASSO 1 de 3</p>
      </div>

      <div>
        <h2 className="font-bold text-3xl text-center my-5">
          Requisitos Técnicos
        </h2>

        <ul>
          <li>
            - Computador com sistema operacional Windows 8 ou superior, Webcam,
            2GB de memoria RAM, acesso a internet de 1MB ou superior;{' '}
          </li>
          <li>
            - Navegadores web: Chrome (versão 45 ou superior), Firefox (versão
            61.0 ou inferior);
          </li>
          <li>
            - Monitor com resolução 1024 x 768 ou superior, estabilizador de
            energia e link redundante de internet;
          </li>
          <li>- Javascript Habilitado;</li>
          <li>- Cookies Habilitados;</li>
        </ul>

        <h2 className="font-bold text-3xl text-center my-5">
          Recomendações ao Candidato
        </h2>

        <p className="mb-2">
          É vedado ao candidato durante a realização do Exame de Certificação:
        </p>

        <ul className="bg-red-200 p-4 rounded-md border border-solid border-red-300 text-red-600">
          <li>
            a) A manipulação e/ou contato com qualquer material de consulta,
            inclusive a terceiros, ou equipamento eletrônico, fone de ouvido,
            celular, bem como o uso de qualquer outro aplicativo eletrônico;
          </li>
          <li>
            b) Fazer anotações de informações relativas às suas respostas em
            folha de rascunho e/ou outro qualquer meio alheio;
          </li>
          <li>c) Copiar, o todo ou em parte, as questões do exame.</li>
        </ul>

        <p className="my-3">
          O candidato compromete-se, ainda, a manter sigilo total em relação aos
          enunciados e respectivas alternativas de respostas de todas as
          questões;
        </p>

        <div className="bg-red-200 p-4 rounded-md border border-solid border-red-300 text-red-600">
          <b className="block">Atenção!</b>
          <b className="block">Leia cuidadosamente:</b>
          <p>
            Se forem abertas outras abas além da prova, redimensionada a tela
            através das teclas "Esc", "Alt", "Ctrl", "Tab", "Shift" ou tecla
            "PRTSCR", aberto um novo navegador ou mudança de foco do navegador
            para outra janela, o processo será encerrado e sua prova será
            enviada.
          </p>
        </div>
      </div>

      <div className="mt-10 p-6 bg-gray-50 border border-solid border-gray-300 rounded-md">
        <h3 className="text-2xl mb-4">Termos Gerais de Realização da Prova</h3>

        {data?.regulation_text && (
          <textarea
            className="w-full rounded-md border-gray-300"
            defaultValue={data?.regulation_text}
          />
        )}

        {data?.regulation?.url && (
          <div className="mt-4">
            <p>
              Leia o{' '}
              <a
                className="text-blue-500 underline font-bold"
                href={data?.regulation?.url}
                target="_blank"
                rel="noreferrer"
              >
                Regulamento
              </a>{' '}
              completo e as informações acima e faça um excelente exame!
            </p>
          </div>
        )}
      </div>

      <div className="mt-10 p-6 bg-gray-50 border border-solid border-gray-300 rounded-md mb-5">
        <form onSubmit={handleSubmit(onChangeStep)}>
          <div className="flex items-center mb-3">
            <input
              {...register('accept')}
              className="mr-2"
              id="accept"
              type="checkbox"
              value="true"
            />
            <label htmlFor="accept">
              Eu, <b className="uppercase">{user?.name}</b> declaro que li e
              aceito os termos do regulamento acima descrito para realizar esta
              prova.
            </label>
          </div>

          <div className="w-20">
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Avançar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
