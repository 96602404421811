import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { apiSlice } from '../../app/api/apiSlice';
import {
  Answer,
  Certification,
  CertificationLogC,
  CertificationStartLogInput,
  CertificationStartLogOutput,
  FinishQuiz,
  Infringement,
  InfringementInput,
  LoadEnrollInput,
  LoadEnrollOutput,
  Question,
  QuestionLog,
  QuestionParsed,
  Quiz,
  QuizLog,
  SendAnswersBook,
  UploadResult,
  WebcamLog,
} from '../../types/certifications/Certification';
import {
  FiscalValidationInput,
  FiscalValidationOutput,
} from '../../types/certifications/FiscalValidation';
import { shuffleArray } from './helpers/shuffleArray';
import { transformQuestionIdentifier } from './helpers/transformQuestionIdentifier';

export const certificationApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCertificationById: mutation<Certification[], string>({
      query: certificationId => ({
        url: `database/certification__c?strict=true&q='_id':'${certificationId}'`,
      }),
    }),

    fiscalValidate: mutation<FiscalValidationOutput, FiscalValidationInput>({
      query: ({ login, password, certification }) => ({
        url: 'database/validate_fiscal_log__c',
        method: 'PUT',
        body: { login, password, certification },
      }),
    }),

    startQuiz: mutation<
      CertificationStartLogOutput,
      CertificationStartLogInput
    >({
      query: data => ({
        url: 'database/certification_start_log__c',
        method: 'PUT',
        body: data,
      }),
    }),

    getCertificationLogById: mutation<CertificationLogC[], string>({
      query: certificationLogId => ({
        url: `database/certification_log__c?strict=true&q='_id':'${certificationLogId}'`,
      }),
    }),

    sendDeviceInformation: mutation<CertificationLogC, CertificationLogC>({
      query: certificationLogC => ({
        url: 'database/certification_log__c',
        method: 'PUT',
        body: certificationLogC,
      }),
    }),

    loadEnroll: query<LoadEnrollOutput[], LoadEnrollInput>({
      query: ({ _id }) => ({
        url: 'database/certification_log__c/aggregate?strict=true',
        method: 'POST',
        body: [
          {
            $match: {
              _id,
            },
          },
          {
            $addFields: {
              now: {
                $date: 'now',
              },
            },
          },
        ],
      }),
    }),

    getQuizLog: mutation<QuizLog[], string>({
      query: certificationId => ({
        url: `database/quiz_log?strict=true&q='_id':'${certificationId}'`,
      }),
    }),

    getQuiz: mutation<Quiz[], string>({
      query: quizId => ({
        url: `database/quiz?q=_id:'${quizId}'`,
      }),
    }),

    finishQuiz: mutation<FinishQuiz, string>({
      query: quizId => ({
        url: `quiz/finish`,
        method: 'POST',
        body: { quiz_log: quizId },
      }),
    }),

    getQuestionsLog: mutation<QuestionLog[], string>({
      query: quizLogId => ({
        url: `database/question_log/aggregate`,
        method: 'POST',
        body: [
          { $match: { quiz_log: quizLogId } },
          { $project: { percent: 0, grade: 0 } },
        ],
      }),
    }),

    getQuestionsParsed: mutation<QuestionParsed[], string>({
      // @ts-expect-error
      queryFn: async (quizId: string, _api, _extraOptions, baseQuery) => {
        const quizResult = await baseQuery({
          url: `database/quiz?q=_id:'${quizId}'`,
          method: 'GET',
        });

        if (quizResult.error) {
          return { error: quizResult.error as FetchBaseQueryError };
        }

        const quiz = quizResult.data as Quiz[];

        if (quiz.length === 0) {
          return { error: 'Quiz not found' } as FetchBaseQueryError;
        }

        // @ts-ignore
        const questionsResult = await baseQuery({
          url: `database/question/aggregate`,
          method: 'POST',
          body: [
            { $match: { quiz: quiz[0]._id } },
            { $project: { 'choices.grade': 0 } },
          ],
        });

        if (questionsResult.error) {
          return { error: questionsResult.error as FetchBaseQueryError };
        }

        const questions = questionsResult.data as Question[];
        if (questions.length === 0) {
          return { error: 'Questions not found' } as FetchBaseQueryError;
        }

        let questionsPayload;

        const howNumberingQuestions =
          quiz[0].questionNumbering || 'arabic_numerals';
        const shouldShuffleQuestions = quiz[0].shuffle || false;

        if (shouldShuffleQuestions) {
          const shuffledQuestions = shuffleArray(questions);

          questionsPayload = shuffledQuestions.map((question, index) => {
            const questionNumbering = transformQuestionIdentifier(
              index,
              howNumberingQuestions,
            );

            if (question.shuffle) {
              question.choices = shuffleArray(question.choices);
            }

            question.choices = question.choices.map((choice, choiceIndex) => ({
              choiceNumbering: transformQuestionIdentifier(
                choiceIndex,
                question.answerNumbering || 'arabic_numerals',
              ),
              ...choice,
            }));

            return {
              ...question,
              questionNumbering,
            };
          });
        } else {
          questionsPayload = questions.map((question, index) => {
            const questionNumbering = transformQuestionIdentifier(
              index,
              howNumberingQuestions,
            );

            if (question.shuffle) {
              question.choices = shuffleArray(question.choices);
            }

            question.choices = question.choices.map((choice, choiceIndex) => ({
              choiceNumbering: transformQuestionIdentifier(
                choiceIndex,
                question.answerNumbering || 'arabic_numerals',
              ),
              ...choice,
            }));

            return {
              ...question,
              questionNumbering,
            };
          });
        }

        return { data: questionsPayload as QuestionParsed[] };
      },
    }),

    getQuestions: mutation<Question[], string>({
      query: quiz => ({
        url: `database/question/aggregate`,
        method: 'POST',
        body: [{ $match: { quiz } }, { $project: { 'choices.grade': 0 } }],
      }),
    }),

    sendAnswers: mutation<SendAnswersBook, Answer[]>({
      query: answers => ({
        url: `question/log/bulk`,
        method: 'POST',
        body: answers,
      }),
    }),

    sendInfringement: mutation<Infringement, InfringementInput>({
      query: payload => ({
        url: `database/violation_log__c`,
        method: 'PUT',
        body: payload,
      }),
    }),

    sendMedia: mutation<UploadResult, FormData>({
      query: payload => ({
        url: `upload/file`,
        method: 'POST',
        body: payload,
      }),
    }),

    sendWebcamLog: mutation<unknown[], WebcamLog>({
      query: payload => ({
        url: `database/webcam_log__c`,
        method: 'PUT',
        body: payload,
      }),
    }),

    sendPing: mutation<{ _id: string }, string>({
      query: payload => ({
        url: `database/certification_ping_log__c`,
        method: 'PUT',
        body: { _id: payload },
      }),
    }),
  }),
});

export const {
  useGetCertificationByIdMutation,
  useFiscalValidateMutation,
  useLoadEnrollQuery,
  useStartQuizMutation,
  useGetQuestionsLogMutation,
  useGetQuestionsMutation,
  useGetQuizLogMutation,
  useGetQuizMutation,
  useSendAnswersMutation,
  useFinishQuizMutation,
  useSendInfringementMutation,
  useSendMediaMutation,
  useSendWebcamLogMutation,
  useSendPingMutation,
  useGetQuestionsParsedMutation,
  useGetCertificationLogByIdMutation,
  useSendDeviceInformationMutation,
} = certificationApiSlice;
