import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../features/auth/authSlice';
import examReducer from '../features/exam/examSlice';
import { apiSlice } from './api/apiSlice';

const rootPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['questions'],
};

/**
 * example of more than one reducer
 *  const examPersistConfig = {
      key: 'exam',
      storage,
      blacklist: ['auth'],
    };

    const persistedExamReducer = persistReducer(examPersistConfig, examReducer);
 */

const persistedAuthReducer = persistReducer(rootPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistedAuthReducer,
    exam: examReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
