import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { Router } from './Router';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Router />
        <Footer />
      </BrowserRouter>

      <ToastContainer />
    </>
  );
}

export default App;
