import { zodResolver } from '@hookform/resolvers/zod';
import pt from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { BsFillTrashFill } from 'react-icons/bs';
import InputMask from 'react-input-mask';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as z from 'zod';

import { Button } from '../../components/Button';
import { ImageEditor } from '../../components/ImageEditor';
import { inputClass, messageError } from '../../styles';
import { Address, Document, Phone } from '../../types/auth/User';
import { useGetGlobalQuery } from '../my-certifications/myCertificationsApiSlice';
import { useGetCepMutation } from '../profile/profileApiSlice';
import { ClearInput } from '../profile/utils/clearInput';
import { RequirementsSchemaSingUp } from '../profile/utils/typeForm';
import {
  useCreateUserMutation,
  useLoadUserQuery,
  useSendMediaRegisterMutation,
} from './signUpApiSlice';

type SignInForm = z.infer<typeof RequirementsSchemaSingUp>;

export type CreateUser = Omit<
  SignInForm,
  'address' | 'documents' | 'mobile' | 'phone'
> & {
  address: Address[];
  documents: Document[];
  phones: Phone[];
};

registerLocale('pt-BR', pt);

export function SignUp() {
  const [image, setImage] = useState<File | string>('');
  const [imageDocFront, setImageDocFront] = useState<File | string>('');
  const [imageDocVerse, setImageDocVerse] = useState<File | string>('');
  const { data: globalData } = useGetGlobalQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const params = useParams();

  const isRenew = searchParams.get('renew') === 'true';

  const paramsId = params?.id || null;

  const { data, isLoading } = useLoadUserQuery(
    { _id: params?.id || '', renew: isRenew },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  const isAdultRequired = true;
  function getDateYearsAgo(yearsAgo: number): Date {
    const currentDate = new Date();

    const pastDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() - yearsAgo),
    );

    return pastDate;
  }

  function getDateAgo(): Date {
    if (isAdultRequired) return getDateYearsAgo(18);
    return getDateYearsAgo(16);
  }

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<CreateUser>({
    mode: 'all',
    resolver: zodResolver(RequirementsSchemaSingUp),
  });

  const [getCep] = useGetCepMutation();
  const [sendMedia] = useSendMediaRegisterMutation();
  const [createUser] = useCreateUserMutation();

  useEffect(() => {
    if (!isLoading && !data) {
      toast.warning('Candidato não encontrado!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate(`/login`);
      return;
    }

    if (data && data?.[0].userAlreadyExists) {
      toast.warning('Candidato já cadastrado!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate(`/login`);
      return;
    }

    if (data && data.length > 0) {
      setValue('name', data?.[0]?.name);
      setValue('email', data?.[0]?.email);
      setValue('extra.cpf', data?.[0]?.cpf);
      setValue('extra.company.0.cnpj', data?.[0]?.cnpj);

      if (data?.[0]?.mobile) {
        setValue('extra.mobile', data?.[0]?.mobile);
      }

      if (data?.[0]?.phone) {
        setValue('extra.phone', data?.[0]?.phone);
      }
    }
    if (data?.[0]?.correspondente?._id) {
      setValue('extra.correspondente', data?.[0]?.correspondente?._id);
      setValue('extra.company.0.name', data?.[0]?.correspondente?.name);
    } else {
      setValue('extra.correspondente', '');
    }
  }, [data, navigate, isLoading, setValue]);

  async function handleMedia(
    item: string | File,
    simple: boolean,
    userId?: string,
  ) {
    if (!item) return null;

    if (simple && typeof item === 'string') return item;
    if (typeof item === 'string')
      return {
        small: {
          url: item,
        },
        medium: {
          url: item,
        },
        original: {
          url: item,
        },
      };

    const form_data = new FormData();
    form_data.append('file', item, `photo.jpg`);
    form_data.append(
      'extra',
      JSON.stringify({
        session: 'images',
        player: userId,
      }),
    );

    const resp = await sendMedia(form_data);

    if (resp && 'data' in resp) {
      const { url } = resp.data.uploads[0];

      if (simple) return url;

      return {
        small: {
          url,
        },
        medium: {
          url,
        },
        original: {
          url,
        },
      };
    }

    return null;
  }

  async function handleForm(user: SignInForm) {
    const sanitizedCpf = user.extra.cpf.replace(/[^\d]/g, '');
    user.extra.address[0].zip =
      user.extra.address[0] &&
      user.extra.address[0].zip &&
      user.extra.address[0].zip.replace(/[^\d]/g, '');

    user.image = await handleMedia(
      user.image.original.url,
      false,
      String(Number(sanitizedCpf)),
    );
    user.extra.documents[0].imageFront = await handleMedia(
      user.extra.documents[0].imageFront,
      true,
      String(Number(sanitizedCpf)),
    );
    user.extra.documents[0].imageVerse = await handleMedia(
      user.extra.documents[0].imageVerse,
      true,
      String(Number(sanitizedCpf)),
    );

    const payload = JSON.parse(JSON.stringify(user));
    delete payload.extra.mobile;
    delete payload.extra.phone;

    payload._id = String(Number(sanitizedCpf));

    payload.extra.cpf = sanitizedCpf;

    if (user?.extra?.company?.[0]?.cnpj) {
      payload.extra.company[0].cnpj = payload.extra.company[0].cnpj.replace(
        /[^\d]/g,
        '',
      );
    }

    payload.extra.phones = [
      { type: 'mobile', phone: user.extra.mobile.replace(/[^\d]/g, '') },
      { type: 'phone', phone: user.extra.phone.replace(/[^\d]/g, '') },
      {
        type: 'otherPhone',
        phone: user.extra.otherPhone.replace(/[^\d]/g, ''),
      },
    ];

    payload.password = payload.password.password;

    if (payload.extra.birthday.$date)
      payload.extra.birthday.$date = new Date(
        payload.extra.birthday.$date,
      ).getTime();

    payload.extra.renew = isRenew;

    try {
      const resp = await createUser(payload);

      if (resp && 'data' in resp && resp?.data?.code === '200') {
        toast.success('Usuário criado com sucesso!', {
          position: 'top-right',
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        navigate(`/login`);
      } else if (
        resp &&
        'data' in resp &&
        resp?.data?.status === 'player already exists'
      ) {
        toast.warning('Candidato já cadastrado!', {
          position: 'bottom-left',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error('Erro ao criar usuário!', {
          position: 'top-right',
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error(error);

      toast.error('Erro ao criar usuário!', {
        position: 'top-right',
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  async function handleCep(cep: React.ChangeEvent<HTMLInputElement>) {
    const value = cep.target.value.replace(/[^\d]/g, '');
    if (value.length < 8) return;
    const resp = await getCep(value);

    if (resp && 'data' in resp) {
      const cepFound = resp.data;

      setValue('extra.address.0.city', cepFound.localidade);
      setValue('extra.address.0.complement', cepFound.complemento);
      setValue('extra.address.0.state', cepFound.uf);
      setValue('extra.address.0.district', cepFound.bairro);
      setValue('extra.address.0.address', cepFound.logradouro);
      setValue('extra.address.0.country', 'Brasil');
      trigger('extra.address.0');
    }
  }

  function handleImg(item: File | string) {
    if (!item) return '';
    if (typeof item === 'string') return item;
    return URL.createObjectURL(item);
  }

  if (!globalData || (paramsId && !data)) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 md:px-24 py-10">
      <h1 className="mb-10 text-2xl font-bold">
        {paramsId ? 'Complete seu cadastro' : 'Cadastre-se'}
      </h1>
      <form className="space-y-5" onSubmit={handleSubmit(handleForm)}>
        <div>
          <label htmlFor="name">Nome</label>
          <input
            className={inputClass}
            id="name"
            type="text"
            disabled={!!paramsId}
            {...register('name')}
          />
          <span className={messageError}>{errors?.name?.message}</span>
        </div>

        <div>
          <label htmlFor="email">E-mail</label>
          <input
            className={inputClass}
            id="email"
            type="email"
            disabled={!!paramsId}
            {...register('email')}
          />
          <span className={messageError}>{errors?.email?.message}</span>
        </div>

        <div className="flex justify-between space-x-2">
          <div className="w-full">
            <label htmlFor="password">Senha</label>
            <input
              className={inputClass}
              id="password"
              type="password"
              {...register('password.password')}
            />

            <span className={messageError}>
              {errors?.password?.password?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="confirmPassword">Confirme sua senha</label>
            <input
              className={inputClass}
              id="confirmPassword"
              type="password"
              {...register('password.confirm')}
            />
            <span className={messageError}>
              {errors?.password?.confirm?.message}
            </span>
          </div>
        </div>

        <div className="flex justify-between space-x-2">
          <div className="w-full">
            <label htmlFor="date">Aniversário</label>
            <Controller
              name="extra.birthday.$date"
              control={control}
              render={() => (
                <DatePicker
                  placeholderText="Data"
                  locale="pt-BR"
                  className={inputClass}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  maxDate={getDateAgo()}
                  minDate={getDateYearsAgo(100)}
                  selected={getValues('extra.birthday.$date')}
                  onChange={e => {
                    setValue('extra.birthday.$date', e!);
                    trigger('extra.birthday.$date');
                  }}
                />
              )}
            />

            <span className={messageError}>
              {errors?.extra?.birthday?.$date?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="cpf">CPF</label>
            <Controller
              render={({ field }) => (
                <InputMask
                  {...field}
                  {...register('extra.cpf')}
                  mask="999.999.999-99"
                  className={inputClass}
                  disabled={!!paramsId}
                />
              )}
              control={control}
              name="extra.cpf"
            />
            {errors?.extra?.cpf?.message && (
              <span
                className={messageError}
              >{`${errors?.extra?.cpf?.message}`}</span>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between md:space-x-2">
          <div className="w-full">
            <label htmlFor="document">Tipo de documento</label>
            <select
              className={inputClass}
              id="document"
              {...register('extra.documents.0.type')}
            >
              <option value="">Clique para selecionar</option>
              <option value="RG">RG</option>
              <option value="CNH">CNH</option>
              <option value="Carteira de Trabalho">Carteira de Trabalho</option>
              <option value="Passaporte">Passaporte</option>
              <option value="CRC">CRC</option>
              <option value="OAB">OAB</option>
              <option value="CREA">CREA</option>
              <option value="CRM">CRM</option>
            </select>

            <span className={messageError}>
              {/* @ts-ignore */}
              {errors?.extra?.documents?.[0]?.type?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="numberDoc">Número</label>
            <input
              className={inputClass}
              id="numberDoc"
              type="number"
              {...register('extra.documents.0.number')}
            />
            <span className={messageError}>
              {errors?.extra?.documents?.[0]?.number?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="emissor">Emissor</label>
            <input
              className={inputClass}
              id="emissor"
              type="text"
              {...register('extra.documents.0.issuer')}
            />
            <span className={messageError}>
              {errors?.extra?.documents?.[0]?.issuer?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="expedicao">Expedição</label>
            <Controller
              name="extra.documents.0.date.$date"
              control={control}
              render={() => (
                <DatePicker
                  className={inputClass}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  selected={getValues('extra.documents.0.date.$date')}
                  onChange={e => {
                    setValue('extra.documents.0.date.$date', e!);
                    trigger('extra.documents.0.date.$date');
                  }}
                />
              )}
            />

            <span className={messageError}>
              {errors?.extra?.documents?.[0]?.date?.$date?.message}
            </span>
          </div>
        </div>

        <div>
          <label htmlFor="imgDoc">Imagem da frente do seu documento</label>
          {imageDocFront && (
            <>
              <div className="flex items-center">
                <img
                  className="h-10 my-4"
                  src={handleImg(imageDocFront) || ''}
                  alt=""
                />

                <button
                  type="button"
                  className="ml-3 flex items-center p-2 bg-red-400 rounded-md text-white"
                  onClick={() => {
                    setImageDocFront('');
                    setValue('extra.documents.0.imageFront', '');
                    ClearInput('imgDocFront');
                    trigger('extra.documents.0.imageFront');
                  }}
                >
                  Remover <BsFillTrashFill className="ml-2" size={20} />
                </button>
              </div>

              {imageDocFront && typeof imageDocFront !== 'string' && (
                <ImageEditor
                  aspect={0.7}
                  src={imageDocFront as Blob}
                  onUpdate={(img, url) => {
                    setValue('extra.documents.0.imageFront', img);
                    setImageDocFront(url);
                    trigger('extra.documents.0.imageFront');
                  }}
                />
              )}
            </>
          )}

          <input
            className={inputClass}
            id="imgDocFront"
            type="file"
            accept="image/*"
            onChange={e => {
              if (e?.target?.files?.[0]) {
                setImageDocFront(e.target.files[0]);
                trigger('extra.documents.0.imageFront');
              }
            }}
          />

          <span className={messageError}>
            {/* @ts-ignore */}
            {errors?.extra?.documents?.[0]?.imageFront?.message}
          </span>

          <span className="text-gray-400 text-sm">
            Tire uma foto da frente do seu documento centralizando na câmera com
            foco bem nítido.
          </span>
        </div>

        <div>
          <label htmlFor="imgDoc">Imagem do Verso do Documento </label>
          {imageDocVerse && (
            <>
              <div className="flex items-center">
                <img
                  className="h-10 my-4"
                  src={handleImg(imageDocVerse) || ''}
                  alt=""
                />

                <button
                  type="button"
                  className="ml-3 flex items-center p-2 bg-red-400 rounded-md text-white"
                  onClick={() => {
                    setImageDocVerse('');
                    setValue('extra.documents.0.imageVerse', '');
                    ClearInput('imgDocVerse');
                    trigger('extra.documents.0.imageVerse');
                  }}
                >
                  Remover <BsFillTrashFill className="ml-2" size={20} />
                </button>
              </div>
              {imageDocVerse && typeof imageDocVerse !== 'string' && (
                <ImageEditor
                  aspect={0.7}
                  src={imageDocVerse as Blob}
                  onUpdate={(img, url) => {
                    setValue('extra.documents.0.imageVerse', img);
                    setImageDocVerse(url);
                    trigger('extra.documents.0.imageVerse');
                  }}
                />
              )}
            </>
          )}

          <input
            className={inputClass}
            id="imgDocVerse"
            type="file"
            accept="image/*"
            onChange={e => {
              if (e?.target?.files?.[0]) {
                setImageDocVerse(e.target.files[0]);
                trigger('extra.documents.0.imageVerse');
              }
            }}
          />

          <span className={messageError}>
            {/* @ts-ignore */}
            {errors?.extra?.documents?.[0]?.imageVerse?.message}
          </span>

          <span className="text-gray-400 text-sm">
            Tire uma foto do verso do documento centralizando na câmera com foco
            bem nítido.
          </span>
        </div>

        <div>
          <label htmlFor="imgPro">Foto atualizada</label>
          {image && (
            <>
              <div className="flex items-center">
                <img
                  className="h-10 my-4"
                  src={handleImg(image) || ''}
                  alt=""
                />

                <button
                  type="button"
                  className="ml-3 flex items-center p-2 bg-red-400 rounded-md text-white"
                  onClick={() => {
                    setImage('');
                    setValue('image.original.url', '');
                    ClearInput('imgPro');
                    trigger('image');
                  }}
                >
                  Remover <BsFillTrashFill className="ml-2" size={20} />
                </button>
              </div>

              {image && typeof image !== 'string' && (
                <ImageEditor
                  aspect={0.7}
                  src={image as Blob}
                  onUpdate={(img, url) => {
                    setValue('image.original.url', img);
                    setImage(url);
                    trigger('image');
                  }}
                />
              )}
            </>
          )}
          <input
            className={inputClass}
            id="imgPro"
            type="file"
            accept="image/*"
            onChange={e => {
              if (e?.target?.files?.[0]) {
                setImage(e.target.files[0]);
                setValue('image.original.url', e.target.files[0]);
                trigger('image');
              }
            }}
          />

          <span className={messageError}>
            {/* @ts-ignore */}
            {errors?.image?.message}
          </span>

          <span className="text-gray-400 text-sm">
            Tire uma self seguindo as seguintes instruções: uma imagem
            atualizada, sem acessórios como óculos ou boné, centralize o rosto
            na câmera com foco bem nítido.
          </span>
        </div>

        <div className="flex space-x-2">
          <div className="w-full">
            <label htmlFor="mobile">Telefone Celular</label>
            <Controller
              render={({ field }) => (
                <InputMask
                  {...field}
                  {...register('extra.mobile')}
                  mask="(99)99999-9999"
                  className={inputClass}
                />
              )}
              control={control}
              name="extra.mobile"
            />
            <span className={messageError}>
              {errors?.extra?.mobile?.message}
            </span>

            <span className="text-gray-400 text-sm">
              Para agilizar a validação do seu certificado, inclua um telefone
              de contato direto com você.
            </span>
          </div>
        </div>

        <div className="flex space-x-2">
          <div className="w-full">
            <label htmlFor="phone">Telefone Comercial</label>
            <Controller
              render={({ field }) => (
                <InputMask
                  {...field}
                  {...register('extra.phone')}
                  mask="(99)9999-9999"
                  className={inputClass}
                />
              )}
              control={control}
              name="extra.phone"
            />
            <span className={messageError}>
              {errors?.extra?.phone?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="phone">Telefone (outros)</label>
            <Controller
              render={({ field }) => (
                <InputMask
                  {...field}
                  {...register('extra.otherPhone')}
                  mask="(99)9999-9999"
                  className={inputClass}
                />
              )}
              control={control}
              name="extra.otherPhone"
            />
            <span className={messageError}>
              {errors?.extra?.otherPhone?.message}
            </span>
          </div>
        </div>

        <div>
          <label htmlFor="zip">CEP</label>
          <Controller
            render={({ field }) => (
              <InputMask
                {...field}
                {...register('extra.address.0.zip')}
                mask="99999-999"
                className={inputClass}
                onChange={e => {
                  handleCep(e);
                  setValue('extra.address.0.zip', e.target.value);
                }}
              />
            )}
            control={control}
            name="extra.address.0.zip"
          />
          <span className={messageError}>
            {errors?.extra?.address?.[0]?.zip?.message}
          </span>
        </div>

        <div>
          <label htmlFor="endereco">Endereço Residencial</label>
          <input
            className={inputClass}
            id="endereco"
            type="text"
            {...register('extra.address.0.address')}
          />
          <span className={messageError}>
            {errors?.extra?.address?.[0]?.address?.message}
          </span>
        </div>

        <div className="flex space-x-2">
          <div className="w-full">
            <label htmlFor="number">Número</label>
            <input
              className={inputClass}
              id="number"
              type="string"
              {...register('extra.address.0.number')}
            />
            <span className={messageError}>
              {errors?.extra?.address?.[0]?.number?.message}
            </span>
          </div>

          <div className="w-full">
            <label htmlFor="complement">Complemento</label>
            <input
              className={inputClass}
              id="complement"
              type="text"
              {...register('extra.address.0.complement')}
            />
            <span className={messageError}>
              {errors?.extra?.address?.[0]?.complement?.message}
            </span>
          </div>
        </div>

        <div>
          <label htmlFor="bairro">Bairro</label>
          <input
            className={inputClass}
            id="bairro"
            type="text"
            {...register('extra.address.0.district')}
          />
          <span className={messageError}>
            {errors?.extra?.address?.[0]?.district?.message}
          </span>
        </div>

        <div className="flex justify-between space-x-2">
          <div className="w-1/2">
            <label htmlFor="city">Cidade</label>
            <input
              className={inputClass}
              id="city"
              type="text"
              {...register('extra.address.0.city')}
            />
            <span className={messageError}>
              {errors?.extra?.address?.[0]?.city?.message}
            </span>
          </div>

          <div className="w-1/2">
            <label htmlFor="state">Estado</label>
            <select
              className={inputClass}
              id="state"
              {...register('extra.address.0.state')}
            >
              <option value="">Clique para selecionar</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AM">Amazonas</option>
              <option value="AP">Amapá</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espirito Santo</option>
              <option value="GO">Goias</option>
              <option value="MA">Maranhão</option>
              <option value="MG">Minas Gerais</option>
              <option value="MS">Mato Grosso Sul</option>
              <option value="MT">Mato Grosso</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraiba</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="PR">Parana</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="SC">Santa Catarina</option>
              <option value="SE">Sergipe</option>
              <option value="SP">São Paulo</option>
              <option value="TO">Tocantins</option>
            </select>

            <span className={messageError}>
              {errors?.extra?.address?.[0]?.state?.message}
            </span>
          </div>
        </div>

        <hr />

        <h2>Dados do Empregador</h2>
        <div className="flex justify-between space-x-2">
          <div className="w-full">
            <label htmlFor="cnpj">CNPJ</label>
            <Controller
              render={({ field }) => (
                <InputMask
                  {...field}
                  {...register('extra.company.0.cnpj')}
                  mask="99.999.999/9999-99"
                  className={inputClass}
                  disabled={!!paramsId}
                />
              )}
              control={control}
              name="extra.company.0.cnpj"
            />
            {errors?.extra?.company?.[0]?.cnpj?.message && (
              <span
                className={messageError}
              >{`${errors?.extra?.company?.[0]?.cnpj?.message}`}</span>
            )}

            {errors?.extra?.correspondente?.message && (
              <span
                className={messageError}
              >{`${errors?.extra?.correspondente?.message}`}</span>
            )}
          </div>

          <div className="w-full">
            <label htmlFor="company">Empresa</label>
            <input
              className={inputClass}
              disabled={!!paramsId}
              id="company"
              type="text"
              {...register('extra.company.0.name')}
            />

            <span className={messageError}>
              {errors?.extra?.company?.[0]?.name?.message}
            </span>

            <span className="text-gray-400 text-sm">
              Ex: Lotérica Brasília LTDA
            </span>
          </div>
        </div>

        <div className="w-fit">
          <Button
            disabled={!globalData?.loginEnable || isSubmitting}
            loading={isSubmitting}
            type="submit"
          >
            {paramsId ? 'Completar cadastro' : 'Finalizar cadastro'}
          </Button>
        </div>
      </form>
    </div>
  );
}
