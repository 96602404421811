import romanize from 'romanize';

export function transformQuestionIdentifier(
  index: number,
  type: 'arabic_numerals' | 'roman_numerals' | 'uppercase_letters',
) {
  switch (type) {
    case 'arabic_numerals':
      return index + 1;
    case 'roman_numerals':
      return romanize(index + 1);
    case 'uppercase_letters':
      if (index < 26) {
        return String.fromCharCode(65 + index);
      }

      return (
        String.fromCharCode(65 + Math.floor(index / 26) - 1) +
        String.fromCharCode(65 + (index % 26))
      );

    default:
      return index + 1;
  }
}
