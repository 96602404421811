import { PixelCrop } from 'react-image-crop';

// const TO_RADIANS = Math.PI / 180;

export function canvasPreview(
  image: HTMLImageElement,
  // canvas: HTMLCanvasElement,
  crop: PixelCrop,
  // scale = 1,
  // rotate = 0,
): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio * 2.5;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx!.imageSmoothingQuality = 'high';

  ctx!.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return canvas;
}
