type PropsLogo = {
  color?: string;
  width?: number;
};

export function Logo({ color = 'white', width = 40 }: PropsLogo) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 385.000000 375.000000"
      aria-label="Assban"
    >
      <path
        d="M2435 3726c-80-36-91-66-169-445-60-295-56-308 119-391 131-61 211-115 307-208 222-215 334-531 298-843-13-116-4-157 43-207 25-27 513-191 592-198 107-10 161 54 190 226 47 284 21 601-73 885-45 138-164 375-240 479-31 43-61 86-67 95-29 47-292 295-375 353-116 82-333 195-456 237-101 34-125 37-169 17zM1235 3691C628 3459 188 2929 70 2289c-40-217-37-507 7-703 26-117 100-174 198-154 88 19 534 169 559 189 44 35 59 74 55 148-15 269 5 426 71 584 98 228 301 434 527 532 93 41 120 59 143 98 27 44 25 80-16 279-81 393-90 420-158 452-53 25-111 19-221-23zM1868 2478c-211-24-392-177-458-387-15-47-20-91-20-170 1-169 48-278 175-400 215-206 548-201 760 13 116 117 165 235 165 399 0 330-287 582-622 545zM1125 1126c-49-22-455-394-471-432-36-85-8-154 100-242C1262 31 1932-91 2549 125c239 83 502 243 639 388 50 54 52 58 52 114 0 39-6 67-18 85-32 48-431 404-470 419-45 17-96 10-139-18-154-105-193-129-260-160-134-62-247-85-413-85-123 1-160 5-245 26-122 31-253 94-360 171-105 76-148 88-210 61z"
        transform="matrix(.1 0 0 -.1 0 375)"
        fill={color}
        aria-label="Logo"
      />
    </svg>
  );
}
