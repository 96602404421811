import { BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaFacebookF, FaWhatsapp } from 'react-icons/fa';

export function Footer() {
  return (
    <footer
      className="px-4 py-10 bg-gray-900 mt-auto w-full"
      data-testid="footer"
    >
      <div className="container mx-auto md:flex justify-between items-center space-y-2 md:space-y-0">
        <p className="text-white">
          ASSBAN - Associação dos Bancos no Distrito Federal
          <br />
          SHCRS Quadra 503, Bloco A, Nº 13 <br />
          Brasília-DF, CEP:70331510 <br />
          E-mail: certifica@assbandf.com.br <br />
          Telefone/FAX: (61) 3224.1883 / 3224.4445
        </p>

        <div className="text-white space-y-1">
          <p> Redes Sociais</p>
          <ul className="list-none flex">
            <li className="bg-slate-600 p-2 rounded-full">
              <a href="https://wa.me/5561995726426" target="_bank">
                <FaWhatsapp size={20} color="white" />
              </a>
            </li>
            <li className="ml-2 bg-slate-600 p-2 rounded-full">
              <a
                href="http://www.facebook.com/?ref=logo#!/assoc.bancos"
                target="_bank"
              >
                <FaFacebookF size={20} color="white" />
              </a>
            </li>
            <li className="ml-2 bg-slate-600 p-2 rounded-full">
              <a href="https://twitter.com/assban_df" target="_bank">
                <BsTwitter size={20} color="white" />
              </a>
            </li>
            <li className="ml-2 bg-slate-600 p-2 rounded-full">
              <a
                href="https://www.youtube.com/channel/UCDYxN1v6rDGTv2hSGBhVEaQ"
                target="_bank"
              >
                <BsYoutube size={20} color="white" />
              </a>
            </li>
          </ul>

          <p>Termo de Uso | Empresas Certificadoras</p>

          <p>
            Copyright <span>{`${new Date().getFullYear()}`}</span> - Todos os
            Direitos Reservados, ASSBAN DF
          </p>

          <span className="mt-4 w-full flex ">
            v{process.env.REACT_APP_VERSION}
          </span>
        </div>
      </div>
    </footer>
  );
}
