import { useEffect } from 'react';

import { useSendPingMutation } from '../../features/exam/certificationApiSlice';

type PingProps = {
  certification_log: string;
};

let timer: ReturnType<typeof setTimeout>;

export function Ping({ certification_log }: PingProps) {
  const [sendPing] = useSendPingMutation();

  async function handlePing() {
    await sendPing(certification_log);
    const oneMinute = 60000;
    timer = setTimeout(() => {
      handlePing();
    }, oneMinute);
  }

  useEffect(() => {
    handlePing();

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
