import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { selectUser } from '../auth/authSlice';
import {
  useGetCertificationByIdMutation,
  useGetQuizLogMutation,
  useLoadEnrollQuery,
} from '../exam/certificationApiSlice';
import { statusTranslate } from '../exam/helpers/statusTranslate';

export function Result() {
  const user = useSelector(selectUser);
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useLoadEnrollQuery(
    { _id: params?.id || '' },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  const [getQuizLog, { data: quizLog }] = useGetQuizLogMutation();
  const [getCertification, { data: certificationItem }] =
    useGetCertificationByIdMutation();

  useEffect(() => {
    const status = window.localStorage.getItem('certifica-updated');

    if (status !== 'true') {
      window.localStorage.setItem('certifica-updated', 'true');
      document.location.reload();
    }

    if (data?.[0].certification) {
      getCertification(data[0].certification);
      getQuizLog(data[0].quiz_log);
    }
  }, [data, getCertification, getQuizLog]);

  const certificationLog = data?.[0];

  if (isLoading || !certificationLog || !quizLog?.[0]) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto pt-10 mb-5">
      <Button
        className="w-[100px] flex justify-center py-2 px-4 mt-1 mb-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
        type="button"
        onClick={() => navigate('/my-certifications')}
      >
        Voltar
      </Button>

      <h1 className="text-2xl mb-2">
        Resultado -{' '}
        {certificationLog?.approved
          ? 'Aprovado'
          : statusTranslate[certificationLog.status]}
      </h1>

      <div className="p-5 rounded-md bg-slate-50 border border-solid border-slate-200 md:flex space-y-2 md:space-y-0 justify-between">
        <ul>
          <li>
            Candidato: <b>{user?.name}</b>
          </li>
          <li>
            Data da Prova:{' '}
            <b>
              {certificationLog?.finished?.$date &&
                new Date(certificationLog.finished.$date).toLocaleDateString()}
            </b>
          </li>
          <li>
            Identificação Candidato: <b>RG</b>
          </li>
          <li>
            Exame: <b>{certificationItem?.[0].title}</b>
          </li>
        </ul>

        <ul>
          <li>
            Quantidade de questões: <b>{quizLog?.[0].questions}</b>
          </li>
          <li>
            Média para aprovação: <b>70%</b>
          </li>
          <li>
            Quantidade de acertos: <b>{quizLog?.[0].questionsGrade}</b>
          </li>
          <li>
            Sua média: <b>{Number(quizLog?.[0]?.percent) * 100}%</b>
          </li>
        </ul>

        {certificationLog?.approved ? (
          <div className="p-3 rounded-md bg-green-200 border border-solid border-green-300 text-green-400 flex items-center">
            <p className="text-lg">Status: Aprovado</p>
          </div>
        ) : (
          <div className="p-3 rounded-md bg-red-200 border border-solid border-red-300 text-red-400 flex items-center">
            <p className="text-lg">
              Status: {statusTranslate[certificationLog.status]}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
