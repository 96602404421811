import { validateBr } from 'js-brasil';
import * as z from 'zod';

const document = z.object({
  type: z.string().optional(),
  number: z.string().optional(),
  issuer: z.string().optional(),
  imageFront: z
    .any()
    .refine(value => !!value, { message: 'Foto da frente obrigatória' }),
  imageVerse: z
    .any()
    .refine(value => !!value, { message: 'Foto do verso obrigatória' }),
  date: z
    .object({
      $date: z
        .date({
          invalid_type_error: 'Data de emissão inválida',
        })
        .optional(),
    })
    .optional(),
});

const company = z.object({
  name: z.string().min(1, { message: 'Nome da empresa obrigatório' }),
  cnpj: z
    .string()
    .min(1, { message: 'CNPJ obrigatório' })
    .refine(cnpj => validateBr.cnpj(cnpj) || !cnpj.replace(/[^\d]/g, ''), {
      message: 'CNPJ inválido.',
    }),
});

const address = z.object({
  city: z.string().min(1, { message: 'Cidade obrigatória' }),
  complement: z.string(),
  number: z.string(),
  state: z.string().min(1, { message: 'Estado obrigatório' }),
  country: z.string().optional(),
  district: z.string().optional(),
  zip: z
    .string()
    .superRefine((val, ctx) => {
      if (val) {
        const isValid = val.replace(/[^\d]/g, '').length > 7;

        if (!isValid) {
          ctx.addIssue({
            code: 'custom',
            message: 'CEP inválido',
          });
        }

        return isValid;
      }
    })
    .optional(),
  address: z.string().optional(),
});

export const RequirementsSchemaSingUp = z.object({
  name: z.string().min(1, { message: 'Nome é obrigatório.' }),
  email: z.string().email({ message: 'Email inválido.' }),
  image: z
    .any()
    .refine(value => !!value, { message: 'Foto atualizada é obrigatória.' }),
  password: z
    .object({
      password: z.string().min(1, { message: 'Senha é obrigatório.' }),
      confirm: z.string().min(1, { message: 'Confirmar senha é obrigatório.' }),
    })
    .refine(data => data.password === data.confirm, {
      message: 'Senhas precisam ser iguais.',
      path: ['confirm'],
    }),
  extra: z.object({
    cpf: z.custom(cpf => validateBr.cpf(cpf), {
      message: 'CPF inválido.',
    }),
    birthday: z.object({
      $date: z.date({
        required_error: 'Data de nascimento é obrigatória.',
        invalid_type_error: 'Data de nascimento inválida.',
      }),
    }),
    documents: z.array(document),
    address: z.array(address),
    company: z.array(company),
    mobile: z.string().min(1, { message: 'Celular é obrigatório.' }),
    phone: z.string(),
    otherPhone: z.string(),
    correspondente: z.string().min(1, { message: 'Correspondente inválido.' }),
    renew: z.boolean().optional(),
  }),
});

export const RequirementsSchemaProfile = z.object({
  _id: z.string(),
  name: z.string().min(1, { message: 'Nome é obrigatório.' }),
  email: z.string().email({ message: 'Email inválido.' }),
  image: z
    .any()
    .refine(value => !!value, { message: 'Foto atualizada é obrigatória.' }),
  password: z
    .object({
      password: z.string(),
      confirm: z.string(),
    })
    .refine(data => data.password === data.confirm, {
      message: 'Senhas precisam ser iguais.',
      path: ['confirm'],
    })
    .nullable(),
  extra: z.object({
    cpf: z.custom(cpf => validateBr.cpf(cpf), {
      message: 'CPF inválido.',
    }),
    birthday: z.object({
      $date: z.date({
        required_error: 'Data de nascimento é obrigatória.',
        invalid_type_error: 'Data de nascimento inválida.',
      }),
    }),
    documents: z.array(document),
    address: z.array(address),
    company: z.array(company),
    mobile: z.string().min(1, { message: 'Celular é obrigatório.' }),
    phone: z.string(),
    otherPhone: z.string(),
  }),
});
