import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import {
  Certification,
  CertificationType,
  CertificationTypEnum,
} from '../../types/certifications/Certification';
import { typeTranslate } from '../../utils/type-translate';
import { Button } from '../Button';

type PropsSelectTypeExam = {
  certification?: Certification;
  onChangeStep: (type: CertificationType) => void;
};

const RequirementsSchema = z.object({
  type: z.enum(CertificationTypEnum),
});

type SignInForm = z.infer<typeof RequirementsSchema>;

export function SelectTypeExam({
  certification,
  onChangeStep,
}: PropsSelectTypeExam) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInForm>({
    mode: 'onBlur',
    resolver: zodResolver(RequirementsSchema),
  });

  const parsedCertification = JSON.parse(
    JSON.stringify(certification),
  ) as Certification;

  if (parsedCertification?.monitoring_select) {
    if (parsedCertification?.monitoring_select.includes('video')) {
      const types = Array.from(parsedCertification.monitoring_select);
      const videoIndex = types.indexOf('video');
      types.splice(videoIndex, 1);
      types.unshift('video');

      parsedCertification.monitoring_select = types;
    }
  }

  return (
    <div className="container mx-auto">
      <div className="md:w-1/2">
        <h1 className="text-2xl mt-10 mb-5">
          Como será feito o seu acompanhamento?
        </h1>

        <form onSubmit={handleSubmit(e => onChangeStep(e.type))}>
          <select
            className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700 mb-3"
            {...register('type')}
          >
            {parsedCertification?.monitoring_select.map(type => (
              <option key={type} value={type}>
                {typeTranslate[type]}
              </option>
            ))}
          </select>

          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            Continuar
          </Button>
        </form>
      </div>
    </div>
  );
}
