type NoDataProps = {
  title: string;
};

export function NoData({ title }: NoDataProps) {
  return (
    <div className="container mx-auto pt-5">
      <div
        className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
        role="alert"
      >
        <p className="font-bold">{title}</p>
      </div>
    </div>
  );
}
