import { useEffect, useState } from 'react';
import { BsFillArrowRightSquareFill } from 'react-icons/bs';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Download from '../../assets/imgs/bt_download.svg';
import MedalOff from '../../assets/imgs/medalhaOff.png';
import MedalOn from '../../assets/imgs/medalhaOn.png';
import { About } from '../../components/About';
import { Certificate } from '../../components/Certificate';
import { Loading } from '../../components/Loading';
import { NoData } from '../../components/NoData';
import { CertificationLog } from '../../types/my-certifications/MyCertification';
import { selectUser } from '../auth/authSlice';
import { statusTranslate } from '../exam/helpers/statusTranslate';
import {
  useGetGlobalQuery,
  useGetMyCertificationsQuery,
} from './myCertificationsApiSlice';

const getCertificationType = {
  first_certification: 'Primeira certificação',
  renovation: 'Renovação',
};

export function MyCertifications() {
  const user = useSelector(selectUser);
  const [log, setLog] = useState<CertificationLog | null>();
  const [isRenew, setIsRenew] = useState(false);

  const { data: globalData } = useGetGlobalQuery(null, {});

  const {
    data: certifications,
    isFetching,
    isLoading,
  } = useGetMyCertificationsQuery(user!._id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    const hasRenew = certifications?.some(
      item => item.certification.certificate_type === 'renovation',
    );

    if (hasRenew) {
      setIsRenew(true);
    }
  }, [certifications]);

  function handleDate(created: number, mouth: number) {
    const originalDate = new Date(created);
    const newDate = new Date(originalDate);
    newDate.setMonth(newDate.getMonth() + mouth);

    return newDate.toLocaleDateString('pt-BR');
  }

  function fixedNumber(number: number) {
    return number.toFixed(2);
  }

  function handleStatus(item: CertificationLog) {
    if (item.status === '3') {
      return (
        <div className="flex">
          <div className="p-3 rounded-md bg-yellow-50 text-yellow-700 mr-3">
            <p className="text-lg font-bold">EM APROVAÇÃO</p>
            <p className="flex items-center">
              <FaArrowAltCircleRight className="mr-2" size={20} /> Aguardando
              aprovação
            </p>
          </div>

          <div className="relative">
            <img className="grayscale" src={MedalOn} alt="Medalha" />
            <p className="text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {fixedNumber(Number(item.quiz_log?.percent) * 100)}
            </p>
          </div>
        </div>
      );
    }

    if (item.status === '5') {
      return (
        <div className="flex">
          <div className="p-3 rounded-md bg-red-200 text-red-400 flex items-center mr-3">
            <p className="text-lg font-bold">REPROVADO</p>
          </div>

          <div className="relative">
            <img src={MedalOff} alt="Medalha" />
            <p className="text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {fixedNumber(Number(item.quiz_log?.percent) * 100)}
            </p>
          </div>
        </div>
      );
    }

    if (item.status === '4') {
      return (
        <div className="flex">
          <div className="p-3 rounded-md bg-green-200 text-green-400">
            <p className="text-lg">APROVADO</p>
            <button
              type="button"
              className="flex items-center hover:underline"
              onClick={() => setLog(item)}
            >
              <FaArrowAltCircleRight className="mr-2" size={20} /> Verificar meu
              certificado
            </button>
          </div>

          <div className="relative">
            <img src={MedalOn} alt="Medalha" />
            <p className="text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {fixedNumber(Number(item.quiz_log?.percent) * 100)}
            </p>
          </div>
        </div>
      );
    }

    return (
      <button
        type="button"
        className="px-2 py-1 bg-gray-600 text-gray-100 font-bold rounded hover:bg-gray-500 cursor-default"
      >
        {statusTranslate[item.status]}
      </button>
    );
  }

  function handleGoingStatus(item: CertificationLog) {
    if (item.status === '1') {
      return (
        <div className="flex ml-auto">
          <div className="px-5 py-2 rounded-md bg-blue-200 text-blue-400 mr-3">
            <p className="text-xl font-bold hover:underline">
              {item.certification.certificate_type === 'renovation'
                ? 'RENOVAÇÃO'
                : 'FAÇA SEU EXAME'}
            </p>
            <p className="flex items-center hover:underline">
              <FaArrowAltCircleRight className="mr-2" size={20} /> Realizar
              prova agora
            </p>
          </div>

          <div className="relative text-cyan-700">
            <BsFillArrowRightSquareFill size={80} />
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-1">
        <div className="px-5 py-2 rounded-md bg-blue-200 text-blue-400 mr-3">
          <p className="text-xl font-bold hover:underline">Em Andamento</p>
          <p className="flex items-center hover:underline">
            <FaArrowAltCircleRight className="mr-2" size={20} /> Continuar
          </p>
        </div>

        <div className="relative text-cyan-700">
          <BsFillArrowRightSquareFill size={80} />
        </div>
      </div>
    );
  }

  if (isLoading) return <Loading />;

  return (
    <div className="p-4">
      <div className="container mx-auto lg:flex">
        <div className="lg:w-1/2 mr-10">
          <About />
        </div>

        <div className="lg:w-1/2">
          {!certifications?.length ? (
            <NoData title="Você não possui nenhuma certificação" />
          ) : (
            <>
              {isRenew && globalData?.apostila?.url && (
                <a
                  href={globalData?.apostila?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1 className="mb-7 font-bold text-2xl">Apostila do Curso</h1>

                  <div className="flex px-6 py-4 bg-white rounded-lg grid shadow-md mb-4 xs:grid-cols-12 md:grid-cols-2">
                    {!!globalData?.capaApostila?.original?.url && (
                      <div className="min-w-[104px] mr-5">
                        <img
                          className="h-full w-auto"
                          src={globalData.capaApostila?.original.url}
                          alt=""
                        />
                      </div>
                    )}

                    <div className="md:flex  items-center">
                      <div className="flex ml-auto">
                        <p className="text-gray-900 text-lg block text-justify">
                          <b className="font-bold text-xl">
                            RENOVAÇÃO - Certificação de Correspondente
                          </b>
                          <br />A nossa apostila está completamente atualizada
                          com os conteúdos necessários para você realizar a
                          renovação da sua certificação com confiança e se
                          manter atualizado para atuar como Correspondente
                          Bancário. Tempo estimado de leitura de 45 min. Bom
                          estudo!
                        </p>
                      </div>
                      <div className="flex ml-auto pl-4">
                        <div className="min-w-[60px] relative text-blue-700">
                          <img
                            className="h-[60px] w-[60px]"
                            src={Download}
                            alt="Ícone de Download"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              )}

              <h1 className="mb-7 font-bold text-2xl">Minhas inscrições</h1>
              {isFetching ? '...recarregando' : ''}

              {certifications.length > 0 &&
                certifications.map(item => (
                  <div
                    key={item._id}
                    className="px-6 py-4 bg-white rounded-lg shadow-md mb-4"
                  >
                    <div className="md:flex  items-center">
                      <div className="lg:w-1/2">
                        <b className="font-bold text-gray-900 block">
                          {
                            getCertificationType[
                              item.certification.certificate_type
                            ]
                          }{' '}
                          - {item.certification.title}
                        </b>
                        {item?.certification?.voucher_validity &&
                          !item.finished && (
                            <span className="text-gray-900">
                              Válido até:{' '}
                              <b>
                                {handleDate(
                                  item.created,
                                  item.certification?.voucher_validity,
                                )}
                              </b>
                            </span>
                          )}
                        {item.finished && (
                          <span className="text-gray-900">
                            Prova realizada em:{' '}
                            <b>
                              {new Date(item.finished).toLocaleDateString()}{' '}
                              {new Date(item.finished).toLocaleTimeString()}
                            </b>
                          </span>
                        )}
                      </div>
                      <div className="lg:flex lg:justify-end lg:w-1/2">
                        {item.status === '1' ? (
                          <Link to={`/exam/${item._id}`} key={item._id}>
                            {handleGoingStatus(item)}
                          </Link>
                        ) : (
                          handleStatus(item)
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      <Certificate log={log} clearLog={() => setLog(null)} />
    </div>
  );
}
