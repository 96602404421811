export interface ApprovedDate {
  $date: string;
}

export interface Approval {
  status: string;
  comment: string;
  time: ApprovedDate;
  by: string;
}

export interface ExtraCandidateGuide {
  session: string;
}

export interface IDate {
  $date: string;
}

export interface CandidateGuide {
  bucket: string;
  extension: string;
  filename: string;
  filename_unique: string;
  size: number;
  content_type: string;
  extra: ExtraCandidateGuide;
  _id: string;
  time: number;
  url: string;
}

export const CertificationTypEnum = [
  'none',
  'fiscal',
  'photo',
  'video',
  'select',
] as const;

export type CertificationType = typeof CertificationTypEnum[number];

export interface Certification {
  _id: string;
  quiz_duration: number;
  certification_validity: number;
  created: number;
  description?: string;
  terms_of_use?: string;
  title: string;
  monitoring: CertificationType;
  monitoring_select: CertificationType[];
  content?: string;
  crcp?: string;
  tech_requirements?: string;
  voucher_validity?: number;
  aproval_routine?: string;
  total_questions?: number;
  price?: number;
  certificate_style?: string;
  updated: number;
  customer?: string;
  repository?: boolean;
}

export interface CertificationStartLogInput {
  _id: string;
  monitoring?: CertificationType;
  origin: string;
  appVersion: string;
  device: string;
}

export interface CertificationStartLogOutput {
  _id: string;
  quiz_log: string;
}

export interface LoadEnrollInput {
  _id: string;
}

export interface LoadEnrollOutput {
  _id: string;
  created: ApprovedDate;
  approval: Approval[];
  lastping: ApprovedDate;
  approved_date: ApprovedDate;
  timeout: ApprovedDate;
  certification: string;
  approved: boolean;
  updated: ApprovedDate;
  player: string;
  status: string;
  quiz: string;
  quiz_log: string;
  started: ApprovedDate;
  finished: ApprovedDate;
  now: ApprovedDate;
}

export interface Answer {
  player: string;
  question: string;
  answer: string[];
  quiz: string;
  quiz_log: string;
}

export interface Started {
  $date: string;
}
export interface QuizLog {
  _id: string;
  player: string;
  quiz: string;
  questions: number;
  answers: number;
  percent: number;
  grade: number;
  questionsGrade: number;
  started: IDate;
  finished: IDate;
}

export interface ExtraQuiz {
  certification: string;
}

export interface Quiz {
  _id: string;
  title: string;
  grade: number;
  extra: ExtraQuiz;
  created: number;
  updated: number;
  feedbacks: unknown[];
  questionNumbering?:
    | 'roman_numerals'
    | 'arabic_numerals'
    | 'uppercase_letters';
  showGradeBeforeFinish: boolean;
  shuffle: boolean;
}

export interface Choice {
  answer: string;
  label: string;
  extra: unknown;
}

export interface ChoiceParsed {
  answer: string;
  label: string;
  extra: unknown;
  choiceNumbering: string;
}

export interface Question {
  _id: string;
  quiz: string;
  type: string;
  title: string;
  question: string;
  grade: number;
  choices: Choice[];
  i18n: unknown;
  select: string;
  feedbacks: unknown[];
  extra: unknown;
  created: number;
  updated: number;
  requires: unknown[];
  answerNumbering?: 'roman_numerals' | 'arabic_numerals' | 'uppercase_letters';
  shuffle: boolean;
}

export interface QuestionParsed {
  _id: string;
  index: number;
  quiz: string;
  type: string;
  title: string;
  question: string;
  grade: number;
  choices: ChoiceParsed[];
  i18n: unknown;
  select: string;
  feedbacks: unknown[];
  extra: unknown;
  created: number;
  updated: number;
  requires: unknown[];
  answerNumbering?: 'roman_numerals' | 'arabic_numerals' | 'uppercase_letters';
  shuffle: boolean;
  questionNumbering: string;
}

export interface Log {
  player: string;
  quiz: string;
  questions: number;
  answers: number;
  percent: number;
  grade: number;
  questionsGrade: number;
  started: number;
  finished: number;
  _id: string;
}

export interface FinishQuiz {
  log: Log;
  feedbacks: null[];
}

export interface LogSendAnswersBook {
  player: string;
  question: string;
  answer: string[];
  percent: number;
  grade: number;
  time: number;
  quiz: string;
  quiz_log: string;
  _id: string;
}
export interface Registered {
  log: LogSendAnswersBook;
}

export interface SendAnswersBook {
  total_registered: number;
  registered: Registered[];
}

export interface Infringement {
  certification_log: string;
  time: number;
  total: number;
  _id: string;
}

export interface Browser {
  version: string;
}

export interface InfringementInput {
  certification_log: string;
  browser: Browser;
}

export interface WebcamLog {
  _id?: string;
  time: Date;
  url: string;
}

export interface UploadResult {
  status: string;
  uploads: [
    {
      url: string;
    },
  ];
}

export interface QuestionLog {
  _id: string;
  player: string;
  question: string;
  answer: string[];
  time: number;
  quiz: string;
  quiz_log: string;
}

export interface CertificationLogC {
  _id: string;
  created: number;
  codigo_curso?: string;
  updated?: number;
  status: string;
  certification: string;
  player: string;
  quiz?: string;
  quiz_log?: string;
  started?: number;
  try?: number;
  timeout?: number;
  monitoring?: CertificationType;
  lastping?: number;
  finished?: number;
  extra?: {
    practa_id?: number;
    practa_course?: number;
  };
  service?: string;
  device?: string;
  origin?: 'web' | 'app';
}
