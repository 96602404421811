import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { QuestionParsed } from '../../types/certifications/Certification';

interface ExamState {
  questions: QuestionParsed[];
}

const initialState = {
  questions: [] as QuestionParsed[],
} as ExamState;

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setQuestions: (state: ExamState, action: PayloadAction<ExamState>) => {
      const { questions } = action.payload;

      state.questions = questions;
    },
  },
});

export const { setQuestions } = examSlice.actions;

export default examSlice.reducer;

export const selectQuestion = (state: RootState) => state.exam.questions;
