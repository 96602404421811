import { useEffect } from 'react';

function usePrintScreenDetection(onPrintScreenDetected: () => void) {
  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            onPrintScreenDetected();
            return;
          }
        }
      }
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [onPrintScreenDetected]);
}

export default usePrintScreenDetection;
