import { CertificationResponse } from '../../types/auth/CertificationResponse';

interface Props {
  openModal: () => void;
  data: CertificationResponse[];
}

export function ValidationCertificationModalResult({ openModal, data }: Props) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Resultado da consulta</h3>
            </div>
            <div className="relative p-6 flex-auto">
              {data.map(item => (
                <>
                  <div className="flex" key={item._id}>
                    <div>
                      <p className="text-base font-medium text-gray-700">
                        <b>Certificação</b>: {item.certification}
                      </p>
                      <p className="text-base font-medium text-gray-700">
                        <b>Candidato</b>: {item.candidato}
                      </p>
                      <p className="text-base font-medium text-gray-700">
                        <b>Válido até</b>:{' '}
                        {new Date(
                          item.certification_validity,
                        ).toLocaleDateString('pt-BR')}
                      </p>
                    </div>
                    <div>
                      <p className="bg-green-600 shadow-lg shadow-green-600/50 p-8 rounded-md text-white text-lg">
                        Aprovado
                      </p>
                    </div>
                  </div>
                  <div className="mb-2 mt-2">
                    <hr />
                  </div>
                </>
              ))}
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={openModal}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}
