import { zodResolver } from '@hookform/resolvers/zod';
import { validateBr } from 'js-brasil';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as z from 'zod';

import { useGetRecoveryPasswordCodeMutation } from '../../features/auth/authApiSlice';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { Button } from '../Button';

const RecoveryCodeSchema = z.object({
  username: z.string().min(1, 'Usuário obrigatório'),
});

type RecoveryCodeForm = z.infer<typeof RecoveryCodeSchema>;

interface RecoveryPasswordModalProps {
  // eslint-disable-next-line
  show: boolean | any;
  cancel: () => void;
}

export function RecoveryPasswordModal({
  show,
  cancel,
}: RecoveryPasswordModalProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [getRecoveryPasswordCode] = useGetRecoveryPasswordCodeMutation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    reset,
  } = useForm<RecoveryCodeForm>({
    mode: 'all',
    resolver: zodResolver(RecoveryCodeSchema),
  });

  async function handleRecoveryCode({ username }: RecoveryCodeForm) {
    const isValidCpf = validateBr.cpf(username);

    const parsedUsername = isValidCpf ? sanitizeCpf(username) : username;

    const data = await getRecoveryPasswordCode({
      username: parsedUsername,
    }).unwrap();

    if (data?.sent_to !== undefined && data?.sent_to !== null) {
      cancel();
      reset();

      navigate('/recovery-password', {
        state: { from: location },
        replace: true,
      });

      const contentToast = (
        <>
          <p>
            <strong>Código enviado com sucesso!</strong>
          </p>
          <p>
            Foi enviado um código para o email: <b>{data.sent_to}</b>
            <br />
            Utilize-o para alterar sua senha.
          </p>
        </>
      );

      toast.success(contentToast, {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (data?.message === 'player does not exist') {
      toast.error('Usuário não encontrado', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  if (!show) {
    return null;
  }

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <form
        name="recovery-code-form"
        className="mb-0 space-y-6"
        onSubmit={handleSubmit(handleRecoveryCode)}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Recuperar senha
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-2">
                        Informe seu usuário para iniciar o processo de
                        recuperação de senha.
                      </p>

                      <div>
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Usuário
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="username"
                            placeholder="Entre com seu usuário"
                            autoComplete="username"
                            {...register('username')}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          {errors.username && (
                            <span className="text-red-500">
                              {errors.username.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 disabled:cursor-not-allowed">
                <Button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:cursor-not-allowed"
                  disabled={isSubmitting || !isValid}
                  loading={isSubmitting}
                >
                  Recuperar
                </Button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={cancel}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
