import { useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { toast } from 'react-toastify';

import { Button } from '../Button';
import { checkPermission } from '../Camera/utils/checkPermissions';
import { VideoPreview } from '../VideoPreview';

type PropsEnableMonitoring = {
  onChangeStep: () => void;
};

export function EnableMonitoring({ onChangeStep }: PropsEnableMonitoring) {
  const [getPermission, setGetPermission] = useState(false);

  const { previewStream } = useReactMediaRecorder({
    video: true,
    audio: true,
    askPermissionOnMount: getPermission,
  });

  async function getPermissions() {
    const getCameraPermission = await checkPermission();

    if (!getCameraPermission.hasPermission) {
      toast.error(getCameraPermission.message, {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: 'text-sm font-bold text-justify',
      });
    } else {
      setGetPermission(true);
    }
  }

  return (
    <div className="container mx-auto mt-10">
      <div>
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <p className="text-2xl mb-2">Habilitar Webcam e Audio</p>
          <p className="text-2xl mb-2">PASSO 2 de 3</p>
        </div>

        <div className="md:flex">
          <div className="md:w-1/2">
            <p>
              Por questões de Segurança você será filmado(a) durante a
              realização da prova,{' '}
              <b>
                você deve habilitar sua webcam e o áudio e concordar com a
                captura e transmissão de suas imagens durante a realização de
                sua prova para poder realizá-la.
              </b>{' '}
              Não será permitido que você saia da frente da câmera durante a
              realização da prova. Verifique se você esta aparecendo no vídeo,
              será feita a verificação posteriormente e analisado juntamente
              seus documentos para emissão do seu certificado.
            </p>
          </div>

          <div className="md:w-1/2 md:flex mb-5 md:mb-0">
            <div className="ml-auto">
              <div className="md:ml-auto mb-5 mt-5 md:mt-0 md:w-fit">
                {!getPermission && (
                  <Button onClick={() => getPermissions()}>
                    Habilitar WebCam e Audio
                  </Button>
                )}
                {getPermission && (
                  <Button
                    disabled={!previewStream}
                    loading={!previewStream}
                    onClick={() => onChangeStep()}
                  >
                    Avançar
                  </Button>
                )}
              </div>

              {previewStream && (
                <VideoPreview width={350} height={350} stream={previewStream} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
