import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from 'mic-check';

type CheckPermissionResult = {
  hasPermission: boolean;
  message: string;
};

export async function checkPermission(): Promise<CheckPermissionResult> {
  return requestMediaPermissions()
    .then(() => {
      return {
        hasPermission: true,
        message: 'Permissão concedida',
      };
    })
    .catch((err: MediaPermissionsError) => {
      const { type: permissionType } = err;
      if (permissionType === MediaPermissionsErrorType.SystemPermissionDenied) {
        return {
          hasPermission: false,
          message:
            'Seu navegador não tem permissão para acessar a câmera e o microfone, por favor, verifique as configurações do seu navegador.',
        };
      }

      if (permissionType === MediaPermissionsErrorType.UserPermissionDenied) {
        return {
          hasPermission: false,
          message:
            'Você negou a permissão para o site acessar a câmera e o microfone, por favor, verifique as configurações do seu navegador.',
        };
      }

      if (
        permissionType === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        return {
          hasPermission: false,
          message:
            'Não foi possível iniciar a câmera, por favor, verifique as configurações do seu navegador.',
        };
      }

      return {
        hasPermission: false,
        message:
          'Erro desconhecido, por favor, verifique as configurações do seu navegador.',
      };
    });
}
