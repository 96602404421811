import { useTimer } from 'react-timer-hook';

type PropsTimer = {
  startedTime?: Date;
  expiryTime: number;
  onExpireTime: () => void;
};

export function Timer({
  startedTime = new Date(),
  expiryTime,
  onExpireTime,
}: PropsTimer) {
  const time = new Date(startedTime);
  const oneMinuteLeft = expiryTime - 1;
  time.setSeconds(time.getSeconds() + oneMinuteLeft * 60);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: time,
    onExpire: () => onExpireTime(),
  });

  return (
    <div>
      <p>
        Tempo restante:<b> {hours}h</b>, <b>{minutes}m</b>,<b> {seconds}s</b>
      </p>
    </div>
  );
}
