import { useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { Button } from '../Button';
import { canvasPreview } from './utils/preview';

type PropsLogo = {
  src: Blob;
  aspect?: number;
  onUpdate: (item: Blob | null, url: string) => void;
};

export function ImageEditor({ src, onUpdate, aspect }: PropsLogo) {
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 20,
    y: 10,
    width: 100,
    height: 130,
  });
  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);

  function onSelectFile() {
    if (src) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(src);
    }
  }

  async function drawImg() {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const data = canvasPreview(imgRef.current, completedCrop);

      data.toBlob(
        blob => {
          if (blob) {
            onUpdate(blob, URL.createObjectURL(blob));
          }
        },
        'image/jpeg',
        0.9,
      );
    }
  }

  useEffect(() => {
    onSelectFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  if (!imgSrc) return <div />;

  return (
    <div>
      <div className="w-56">
        <ReactCrop
          crop={crop}
          keepSelection
          className="w-56"
          aspect={aspect}
          onChange={c => {
            setCrop(c);
            setCompletedCrop(c);
          }}
          onComplete={c => setCompletedCrop(c)}
        >
          <img
            ref={imgRef}
            src={imgSrc}
            alt="Imagem sendo editada"
            onLoad={() =>
              setCompletedCrop({
                unit: 'px',
                x: 21,
                y: 11,
                width: 101,
                height: 131,
              })
            }
          />
        </ReactCrop>

        <Button type="button" onClick={() => drawImg()}>
          Salvar
        </Button>
      </div>
    </div>
  );
}
