/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useEffect } from 'react';

type VideoPreviewProps = {
  stream: MediaStream;
  height?: number;
  width?: number;
  rounded?: boolean;
};

export function VideoPreview({
  stream,
  height = 100,
  width = 100,
  rounded = false,
}: VideoPreviewProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return (
    <div
      className={`${
        rounded ? `h-auto w-[${width - 20}px] rounded-full overflow-hidden` : ''
      }`}
    >
      <video
        className={`h-[${height}px] w-[${width}px] ${
          !rounded ? 'rounded-2xl' : ''
        }`}
        ref={videoRef}
        width={width}
        height={height}
        autoPlay
      />
    </div>
  );
}
