export const statusTranslate: { [key: string]: string } = {
  '0': 'Compra não finalizada',
  '1': 'Aberto',
  '2': 'Em Andamento',
  '3': 'Aguardando Avaliação',
  '4': 'Aprovado',
  '5': 'Reprovado',
  '6': 'Fechado',
  '7': 'Prova não finalizada',
  '8': 'Enviado',
  '9': 'Vencido',
};
